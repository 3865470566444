

































































































































import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

import { computed, defineComponent, ref } from "@vue/composition-api";
import buckets from "./prices";
export default defineComponent({
  name: "Calculator",
  components: {
    VueSlider,
  },
  setup() {
    const defaultPackage = ref(buckets[1]);
    const selectedPackage = ref(defaultPackage.value);
    const selectedPackageName = computed(() => selectedPackage.value.name);

    const hubs = ref(0);
    const routes = ref(0);
    const shipments = ref(0);
    const skus = ref(0);

    const resetPackage = () => {
      hubs.value = selectedPackage.value.contains.hubs;
      routes.value = selectedPackage.value.contains.routes;
      shipments.value = selectedPackage.value.contains.shipments;
      skus.value = selectedPackage.value.contains.skus;
    };
    resetPackage();

    const highlightSelectedMark = (keyname: string) => {
      const response = {} as any;
      buckets.forEach((b) => {
        response[(b.contains as any)[keyname]] =
          b.name === selectedPackageName.value
            ? {
                label: (b.contains as any)[keyname],
                labelStyle: { fontWeight: "bold" },
              }
            : (b.contains as any)[keyname];
      });
      return response;
    };

    const hubMarks = computed(() => highlightSelectedMark("hubs"));
    const routeMarks = computed(() => highlightSelectedMark("routes"));
    const shipmentMarks = computed(() => highlightSelectedMark("shipments"));
    const skuMarks = computed(() => highlightSelectedMark("skus"));

    const choosePackage = (b: {
      name: string;
      price: number;
      contains: {
        hubs: number;
        routes: number;
        shipments: number;
        skus: number;
      };
      additionalPrice: number;
      additional: {
        hubs: number;
        routes: number;
        shipments: number;
        skus: number;
      };
    }) => {
      selectedPackage.value = b;
      resetPackage();
    };

    const hubOverage = computed((): number => {
      const selected = buckets.find(
        (b) => b.name === selectedPackageName.value
      );
      if (selected) {
        const overage =
          (hubs.value - selected?.contains.hubs) / selected?.additional.hubs;
        if (overage > 0) {
          return (
            selected?.additionalPrice * Math.ceil(overage) + selected.price
          );
        }
      }
      return 0;
    });

    const routeOverage = computed((): number => {
      const selected = buckets.find(
        (b) => b.name === selectedPackageName.value
      );
      if (selected) {
        const overage =
          (routes.value - selected?.contains.routes) /
          selected?.additional.routes;
        if (overage > 0) {
          return (
            selected?.additionalPrice * Math.ceil(overage) + selected.price
          );
        }
      }
      return 0;
    });

    const shipmentsOverage = computed((): number => {
      const selected = buckets.find(
        (b) => b.name === selectedPackageName.value
      );
      if (selected) {
        const overage =
          (shipments.value - selected?.contains.shipments) /
          selected?.additional.shipments;
        if (overage > 0) {
          return (
            selected?.additionalPrice * Math.ceil(overage) + selected.price
          );
        }
      }
      return 0;
    });

    const skuOverage = computed((): number => {
      const selected = buckets.find(
        (b) => b.name === selectedPackageName.value
      );
      if (selected) {
        const overage =
          (skus.value - selected?.contains.skus) / selected?.additional.skus;
        if (overage > 0) {
          return (
            selected?.additionalPrice * Math.ceil(overage) + selected.price
          );
        }
      }
      return 0;
    });

    const recommendedPackage = computed(() => {
      // const lastBucketIndex = buckets.length - 1;
      // for values over highest package recommend highest?
      const bucket = buckets.find((b) => {
        if (
          hubs.value <= b.contains.hubs &&
          routes.value <= b.contains.routes &&
          shipments.value <= b.contains.shipments &&
          skus.value <= b.contains.skus
        ) {
          return b;
        }
      });
      return bucket;
    });

    const recommend = computed(() => {
      if (
        selectedPackage.value.name !== "Light" &&
        recommendedPackage?.value &&
        recommendedPackage.value.price < totalPrice.value
      ) {
        return recommendedPackage.value;
      }
      return { name: "-", price: "-" };
    });

    const totalPrice = computed(() => {
      let price = selectedPackage.value.price;
      if (hubOverage.value > price) {
        price = hubOverage.value;
      }
      if (routeOverage.value > price) {
        price = routeOverage.value;
      }
      if (shipmentsOverage.value > price) {
        price = shipmentsOverage.value;
      }
      if (skuOverage.value > price) {
        price = skuOverage.value;
      }
      return price;
    });

    return {
      choosePackage,

      selectedPackage,
      selectedPackageName,

      buckets,

      hubs,
      routes,
      shipments,
      skus,

      recommendedPackage,
      totalPrice,
      recommend,

      hubOverage,
      routeOverage,
      shipmentsOverage,
      skuOverage,

      hubMarks,
      routeMarks,
      shipmentMarks,
      skuMarks,

      resetPackage,
    };
  },
});
