






import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import Calculator from "./components/Calculator.vue";

Vue.use(VueCompositionAPI);

export default Vue.extend({
  name: "App",
  components: {
    Calculator,
  },
});
