const buckets: {
  name: string;
  price: number;
  contains: { hubs: number; routes: number; shipments: number; skus: number };
  additionalPrice: number;
  additional: { hubs: number; routes: number; shipments: number; skus: number };
}[] = [
  {
    name: "Light",
    price: 10,
    contains: {
      hubs: 0,
      routes: 0,
      shipments: 100,
      skus: 0,
    },
    additionalPrice: 0.11,
    additional: {
      hubs: 0,
      routes: 0,
      shipments: 1,
      skus: 0,
    },
  },
  {
    name: "Essentials",
    price: 500,
    contains: {
      hubs: 1,
      routes: 60,
      shipments: 1000,
      skus: 5000,
    },
    additionalPrice: 400,
    additional: {
      hubs: 1,
      routes: 100,
      shipments: 1000,
      skus: 5000,
    },
  },
  {
    name: "Business",
    price: 2000,
    contains: {
      hubs: 5,
      routes: 300,
      shipments: 2000,
      skus: 10000,
    },
    additionalPrice: 750,
    additional: {
      hubs: 1,
      routes: 150,
      shipments: 1000,
      skus: 5000,
    },
  },
  {
    name: "Business+",
    price: 4000,
    contains: {
      hubs: 8,
      routes: 750,
      shipments: 5000,
      skus: 25000,
    },
    additionalPrice: 600,
    additional: {
      hubs: 1,
      routes: 150,
      shipments: 1000,
      skus: 5000,
    },
  },
  {
    name: "Enterprise",
    price: 7500,
    contains: {
      hubs: 15,
      routes: 1500,
      shipments: 10000,
      skus: 50000,
    },
    additionalPrice: 500,
    additional: {
      hubs: 2,
      routes: 150,
      shipments: 1000,
      skus: 5000,
    },
  },
];
export default buckets;
